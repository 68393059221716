import * as React from 'react'
import Layout from '../components/layout'
import bCyberLogo from '../images/bcyber-logo.svg'
import blocktrainerLogo from '../images/blocktrainer-logo.svg'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const logoStyle = {
  maxHeight: '150px'
}

// todo: frontpage kasten hintergrund zu heftig wegen schwarz/weiß - scrollbalken auf index/windows

const IndexPage = () => {
  return (
    <Layout pageTitle="Home">
      <div className="container">
        <div className="row mt-5">
          <div className="col text-center">
            <img style={logoStyle} src={bCyberLogo} alt="bCyber Logo" />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col"><h1>Projekte</h1></div>
        </div>
        <div className="row gy-5">
          <div className="col-md-6">
            <div className="bc-card p-5 h-100 bg-dark">
              <div className=" mb-3">
                <a href="https://www.blocktrainer.de" rel="noreferrer" target="_blank"><img src={blocktrainerLogo} alt="Logo Blocktrainer" /></a>
              </div>
              <a className="text-decoration-none" href="https://www.blocktrainer.de" rel="noreferrer" target="_blank"><h2>Blocktrainer</h2></a>
              <p>Deutschlands größter YouTube Kanal zum Thema Bitcoin, Kryptowährungen und Blockchain</p>
              <div className="">
                <a className="btn btn-primary btn-lg" href="https://www.blocktrainer.de" rel="noreferrer" target="_blank">Details</a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="bc-card p-5 h-100 bg-dark">
              <div className=" mb-3">
                <Link to="/leezenflow"><StaticImage src="../images/leezenflow.jpg" /></Link>
              </div>
              <Link to="/leezenflow" className="text-decoration-none"><h2>Leezenflow</h2></Link>
              <p>Der Grüne-Welle-Assistent für Fahrräder</p>
              <div className="">
                <Link to="/leezenflow" className="btn btn-primary btn-lg">Details</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage